@import "definitions";

div.design_editor {
    .slot_set.type_colors > div {
        width: 20px;
        height: 20px;
        display: inline-block;
        margin-right: 3px;
        border:solid transparent 2px;
        cursor: pointer;
        &.active, &:hover {
            border-color:#000;
        }
    }  
    div.sidebar_box div.box_head{
        padding: $pad 0;
        margin-bottom: $mar; 
    }
    div.box_head + div{
     margin-bottom: $mar_big;   
    }
    div.box_head + div > div {
        margin-bottom: $mar;
    }
    label{
        margin-bottom: $mar_small;
        //min-width: 12em;
        display: inline-block;
        text-indent: 0;
    }
    label + div > img{
     vertical-align: middle;
     margin-right: $mar;
     margin-top: $mar_small;
    }
    div.tabs div.tabs_head{
        display: flex;
        margin-left: $mar_small
    }
    div.tabs div.tabs_head > div{
        text-indent: 0;
    }
    .edit_images button, .edit_text button, .edit_options button{
        background:#eee;
        border: $border;
        color: #000;
        box-shadow: none;
        cursor: pointer;
        padding: $pad_small;
    }
    .edit_images button:hover, .edit_images button:focus,
     .edit_text button:hover, .edit_text button:focus{
        background: $theme_color;
        color: #fff;
        border-color:$theme_color;
    }
    pre + button{
        margin-left: 2em;
    }
    input[type="file"]{
        margin-left: 2em;
                margin-top: $mar_small;

    }
    input[type="file"] + button, input[type="text"] + button{
        margin-left: 2em;
        margin-top: $mar_small;
    }

    .files_editor_split_pane {
        width:100%;
        display:flex;

        >.files_editor_split_pane_right {
            margin-left: $pad;
            flex: 1;
        }
    }

    .files_editor_split_pane .form_wide > div .form_field_label {
        min-width:1px;
    }

    .tabs_head > div > a {
        display:inline-block;
        padding: .1em .2em;
    }
}


